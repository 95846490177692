import React, { useState } from "react";

import { Col } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import { paisesAutocomplete } from "../../../../shared/data/paises";
import { CloseRounded } from "@material-ui/icons";
import { CheckRounded } from "@material-ui/icons";
import { Button } from "@material-ui/core";

import PersonIcon from "@material-ui/icons/Person";
import swal from "sweetalert";
import { toast } from "react-hot-toast";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Autocomplete, Skeleton } from "@material-ui/lab";
import { DropzoneArea } from "material-ui-dropzone";
import { crearInstitutoClassics } from "../../../../shared/fetchers/classics/institutos/crearInstituto";
import { editarInstitutoClassics } from "../../../../shared/fetchers/classics/institutos/editarInstituto";
import IOSSwitch from "../../../../components/shared/ios-switch";
import { fetchTiposDeIngreso } from "../../../../shared/fetchers/classics/institutos/verTiposDeIngreso";

const CrearInstitutoClassicsIndividual = ({ instituto, clearEdit }) => {
  const queryClient = useQueryClient();

  const [formData, setFormData] = useState({
    nombreInstituto: { value: instituto?.nombre ?? "", error: false },
    correo: { value: instituto?.correo ?? "", error: false },
    pais: { value: instituto?.pais ?? "", error: false },
    lecturasDiarias: { value: instituto?.lecturasDiarias ?? 0, error: false },
    tipoIngreso: {
      value: instituto?.tipoAcceso ?? "",
      error: false,
      optional: true,
    },
    tipoServicio: {
      value: instituto?.tipoServicio ?? "",
      error: false,
      optional: true,
    },
    linkAcceso: {
      value: instituto?.linkAcceso ?? "",
      error: false,
      optional: true,
    },
    linkTutorial: {
      value: instituto?.linkTutorial ?? "",
      error: false,
      optional: true,
    },
    colorHeader: {
      value: instituto?.colorHeader ?? "",
      error: false,
      optional: true,
    },
    colorLetra: {
      value: instituto?.colorLetra ?? "",
      error: false,
      optional: true,
    },
    estadoActivacion: {
      value: Boolean(instituto?.estadoActivacion) ?? false,
      error: false,
      optional: true,
    },
    estadoSubscripcion: {
      value: Boolean(instituto?.estadoSubscripcion) ?? false,
      error: false,
      optional: true,
    },
    ingresoApp: {
      value: Boolean(instituto?.ingresoApp) ?? false,
      error: false,
      optional: true,
    },
    permitirIndividuales: {
      value: Boolean(instituto?.permitirIndividuales) ?? false,
      error: false,
      optional: true,
    },
    logoInstituto: { value: "", error: false, optional: true },
  });

  const {
    data: tiposIngreso,
    isLoading: isLoadingTiposIngreso
  } = useQuery(["tiposIngreso"], fetchTiposDeIngreso);

  //Funcion para actualizar un campo de un formulario
  const updateForm = (e) => {
    const { value, name } = e.target;
    setFormData({
      ...formData,
      [name]: { ...formData[name], value: value, error: false },
    });
  };

  //Funcion para limpiar todo el formulario
  const clearForm = () => {
    let temp = { ...formData };
    for (const formElement in temp) {
      temp[formElement] = { value: "", error: false };
    }
    setFormData(temp);
  };

  const validate = () => {
    let isValid = true;
    //Creamos una variable temporal para actualizar el estado
    const temp = { ...formData };

    //Recorremos todos los campos del formulario y validamos que no esten vacios si no son opcionales
    for (const formElement of Object.entries(temp)) {
      const [key, value] = formElement;

      if (value.optional || value.value === 0 || Boolean(value.value)) continue;

      value.error = true;
      isValid = false;
      toast.error(`El campo ${key} no puede estar vacío`);
    }

    setFormData(temp);
    return isValid;
  };

  const SubmitForm = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    const datosInstituto = Object.fromEntries(
      Object.entries(formData).map(([key, value]) => [key, value.value ?? ""])
    );

    const { info, status } = !instituto
      ? await crearInstitutoClassics(datosInstituto)
      : await editarInstitutoClassics({
        ...datosInstituto,
        nombreInstitutoNuevo: datosInstituto.nombreInstituto,
        nombreInstituto: instituto.nombre,
      });

    if (!status)
      return swal({
        title: "Error",
        text: info,
        icon: "error",
      });

    queryClient.invalidateQueries({
      queryKey: ["institutosClassics"],
    });

    queryClient.invalidateQueries({
      queryKey: ["tiposIngreso"],
    });

    if (instituto) clearEdit();

    !instituto
      ? toast.success("Instituto creado correctamente")
      : toast.success("Instituto editado correctamente");
  };

  if (isLoadingTiposIngreso)
    return (
      <>
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
      </>
    );

  return (
    <Col xs={12} className="content-white-box px-4 py-4">
      <div className="d-flex align-items-center">
        <PersonIcon style={{ fontSize: "17px" }} />
        <div className="admin-main-title-dark-gray ml-2 mt-1">
          {!instituto ? "Crear" : "Editar"} un instituto
        </div>
      </div>
      <div className="admin-muted-text-small mt-2">
        Por favor ingresar la siguiente info:
      </div>
      <form>
        <label className="admin-label-text mt-3 d-block">
          Nombre Instituto
        </label>
        <TextField
          name="nombreInstituto"
          value={formData.nombreInstituto.value}
          onChange={updateForm}
          error={formData.nombreInstituto.error}
          helperText={
            formData.nombreInstituto.error
              ? "Este campo no puede estar vacío"
              : null
          }
          variant="outlined"
          size="small"
          placeholder="Nombre del Instituto"
          style={{
            width: "100%",
            fontFamily: "'Noto Sans JP', sans-serif",
          }}
        />

        <label className="admin-label-text mt-3 d-block">Correo</label>
        <TextField
          name="correo"
          value={formData.correo.value}
          onChange={updateForm}
          error={formData.correo.error}
          helperText={
            formData.correo.error ? "Este campo no puede estar vacío" : null
          }
          variant="outlined"
          size="small"
          placeholder="Correo Electrónico"
          style={{
            width: "100%",
            fontFamily: "'Noto Sans JP', sans-serif",
          }}
        />

        <label className="admin-label-text mt-3 d-block">País</label>
        <Autocomplete
          options={paisesAutocomplete}
          name="pais"
          getOptionLabel={(option) => option}
          value={formData.pais.value}
          onChange={(_, value) =>
            updateForm({
              target: { name: "pais", value },
            })
          }
          noOptionsText="Sin resultados"
          error={formData.pais.error}
          helperText={formData.pais.error ? "Campo no válido" : null}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Selecciona un país"
              variant="outlined"
              size="small"
              error={formData.pais.error}
              helperText={formData.pais.error ? "Campo no válido" : null}
              style={{
                width: "100%",
                fontFamily: "'Noto Sans JP', sans-serif",
              }}
            />
          )}
        />

        <label className="admin-label-text mt-3 d-block">
          Lecturas diarias (0 para desactivar el límite)
        </label>
        <TextField
          name="lecturasDiarias"
          type="number"
          value={formData.lecturasDiarias.value}
          onChange={updateForm}
          error={formData.lecturasDiarias.error}
          helperText={
            formData.lecturasDiarias.error
              ? "Este campo no puede estar vacío"
              : null
          }
          variant="outlined"
          size="small"
          placeholder="Número de lecturas diarias"
          style={{
            width: "100%",
            fontFamily: "'Noto Sans JP', sans-serif",
          }}
        />

        <label className="admin-label-text mt-3 d-block">Tipo Ingreso</label>
        <Autocomplete
          options={tiposIngreso.filter((tipo) => tipo)}
          freeSolo
          name="tipoIngreso"
          getOptionLabel={(option) => option}
          value={formData.tipoIngreso.value}
          inputValue={formData.tipoIngreso.value}
          onInputChange={(_, value) =>
            updateForm({
              target: { name: "tipoIngreso", value },
            })
          }
          noOptionsText="Sin resultados"
          error={formData.tipoIngreso.error}
          helperText={formData.tipoIngreso.error ? "Campo no válido" : null}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Tipos de ingreso"
              variant="outlined"
              size="small"
              error={formData.tipoIngreso.error}
              helperText={formData.tipoIngreso.error ? "Campo no válido" : null}
              style={{
                width: "100%",
                fontFamily: "'Noto Sans JP', sans-serif",
              }}
            />
          )}
        />

        <label className="admin-label-text mt-3 d-block">Tipo Servicio</label>
        <TextField
          name="tipoServicio"
          value={formData.tipoServicio.value}
          onChange={updateForm}
          error={formData.tipoServicio.error}
          helperText={
            formData.tipoServicio.error
              ? "Este campo no puede estar vacío"
              : null
          }
          variant="outlined"
          size="small"
          placeholder="Tipo Servicio"
          style={{
            width: "100%",
            fontFamily: "'Noto Sans JP', sans-serif",
          }}
        />

        <label className="admin-label-text mt-3 d-block">Link Acceso</label>
        <TextField
          name="linkAcceso"
          value={formData.linkAcceso.value}
          onChange={updateForm}
          error={formData.linkAcceso.error}
          helperText={
            formData.linkAcceso.error ? "Este campo no puede estar vacío" : null
          }
          variant="outlined"
          size="small"
          placeholder="Link Acceso"
          style={{
            width: "100%",
            fontFamily: "'Noto Sans JP', sans-serif",
          }}
        />

        <label className="admin-label-text mt-3 d-block">Link Tutorial</label>
        <TextField
          name="linkTutorial"
          value={formData.linkTutorial.value}
          onChange={updateForm}
          error={formData.linkTutorial.error}
          helperText={
            formData.linkTutorial.error
              ? "Este campo no puede estar vacío"
              : null
          }
          variant="outlined"
          size="small"
          placeholder="Link Tutorial"
          style={{
            width: "100%",
            fontFamily: "'Noto Sans JP', sans-serif",
          }}
        />

        <div className="d-flex">
          <label className="admin-label-text mt-3 d-block">
            Estado Activación
          </label>
          <IOSSwitch
            checked={formData.estadoActivacion.value}
            onClick={() => {
              updateForm({
                target: {
                  name: "estadoActivacion",
                  value: !formData.estadoActivacion.value,
                },
              });
            }}
          />
        </div>

        <div className="d-flex">
          <label className="admin-label-text mt-3 d-block">
            Estado Subscripción
          </label>
          <IOSSwitch
            checked={formData.estadoSubscripcion.value}
            onClick={() => {
              updateForm({
                target: {
                  name: "estadoSubscripcion",
                  value: !formData.estadoSubscripcion.value,
                },
              });
            }}
          />
        </div>

        <div className="d-flex">
          <label className="admin-label-text mt-3 d-block">
            Ingreso App
          </label>
          <IOSSwitch
            checked={formData.ingresoApp.value}
            onClick={() => {
              updateForm({
                target: {
                  name: "ingresoApp",
                  value: !formData.ingresoApp.value,
                },
              });
            }}
          />
        </div>

        <div className="d-flex">
          <label className="admin-label-text mt-3 d-block">
            Clientes Individuales
          </label>
          <IOSSwitch
            checked={formData.permitirIndividuales.value}
            onClick={() => {
              updateForm({
                target: {
                  name: "permitirIndividuales",
                  value: !formData.permitirIndividuales.value,
                },
              });
            }}
          />
        </div>

        <label className="admin-label-text mt-3 d-block">Color Header</label>
        <TextField
          name="colorHeader"
          value={formData.colorHeader.value}
          onChange={updateForm}
          error={formData.colorHeader.error}
          helperText={
            formData.colorHeader.error
              ? "Este campo no puede estar vacío"
              : null
          }
          variant="outlined"
          size="small"
          type="color"
          style={{ width: "30%", fontFamily: "'Noto Sans JP', sans-serif" }}
        />

        <label className="admin-label-text mt-3 d-block">Color Letras</label>
        <TextField
          name="colorLetra"
          value={formData.colorLetra.value}
          onChange={updateForm}
          error={formData.colorLetra.error}
          helperText={
            formData.colorLetra.error ? "Este campo no puede estar vacío" : null
          }
          variant="outlined"
          size="small"
          type="color"
          style={{ width: "30%", fontFamily: "'Noto Sans JP', sans-serif" }}
        />

        <label className="admin-label-text mt-3 d-block">
          Logo del Instituto
        </label>
        <DropzoneArea
          id="dropzone"
          dropzoneText="Arrastra un archivo o haz click aquí!"
          showFileNames={true}
          showAlerts={true}
          filesLimit={1}
          acceptedFiles={["image/*"]}
          onChange={(files) =>
            updateForm({
              target: {
                name: "logoInstituto",
                value: files[0],
              },
            })
          }
        />

        <div className="d-flex justify-content-end mt-4">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={(e) => SubmitForm(e)}
            startIcon={<CheckRounded />}
            style={{ background: "#81c868" }}
          >
            Finalizar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              clearForm();
              if (instituto) clearEdit();
            }}
            className="ml-2"
            startIcon={<CloseRounded style={{ fontSize: "25px" }} />}
            style={{ background: "#f05050" }}
          >
            Cancelar
          </Button>
        </div>
      </form>
    </Col>
  );
};

export default CrearInstitutoClassicsIndividual;
